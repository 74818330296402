<template>
  <div class="utility-navigation">
    <div class="mobile-options">
      <button
        class="mobile-menu-trigger"
        @click="$emit('activate-mobile-menu')"
      >
        <span class="sr-only">Menu</span>
        <AlertBadge />
      </button>
      <button class="mobile-search-trigger" @click="openMobileSearch">
        <span class="sr-only">Mobile Search</span>
      </button>
    </div>
    <div class="account">
      <nav aria-label="Account Navigation">
        <DesktopMenu :menu="myAccountMenu" />
      </nav>
    </div>
    <div class="logo">
      <div class="logo">
        <nuxt-link to="/">
          <span class="sr-only">Wine Access</span>
        </nuxt-link>
      </div>
    </div>
    <div class="search-cart">
      <Search
        aria-id="utility"
        :mobile="mobileSearchActive"
        @closed="mobileSearchActive = false"
      />
      <div class="cart">
        <button
          ref="cart"
          aria-haspopup="true"
          :aria-expanded="`${toggleCart}`"
          @click.stop="$store.dispatch('toggleCart')"
        >
          <span
            v-if="cart.num_bottles > 0"
            :class="{ badge: true, long: cart.num_bottles >= 100 }"
          >
            <span>{{ cart.num_bottles }}</span>
          </span>
          <span class="sr-only">items in cart</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import accountMenus from './accountMenus.json'
import AlertBadge from '~/components/Header/AlertBell/AlertBadge'

export default {
  components: {
    AlertBadge,
    DesktopMenu: () => import('~/components/Header/NavigationMenu/DesktopMenu'),
    Search: () => import('~/components/Header/Search'),
  },
  data() {
    return {
      mobileSearchActive: false,
      myAccountMenu: accountMenus,
    }
  },
  computed: {
    toggleCart() {
      return this.$store.getters.toggleCart
    },
    ...mapGetters({
      cart: 'cart',
    }),
  },
  watch: {
    toggleCart(newValue) {
      if (!newValue) {
        this.$refs.cart.focus()
      }
    },
  },
  methods: {
    openMobileSearch() {
      this.$store.dispatch('setMobileSearchOpen', true)
    },
  },
}
</script>
