<template>
  <span v-if="showNewProductNotification" class="badge">{{
    newProductNotifications.length
  }}</span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AlertBadge',
  computed: {
    showNewProductNotification() {
      return this.newProductNotifications.length > 0
    },
    ...mapGetters([
      'notifications',
      'newProductNotifications',
      'notificationBell',
    ]),
  },
}
</script>
